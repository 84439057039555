@import 'src/variables.scss';

p,
.snc-label {
    margin-bottom: 0px;
}

.snc-label {
    font-weight: 600;
}

.section-label {
    color: $primary_color;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInVisibility {
    0% {
        opacity: 0;
        visibility: hidden !important;
    }
    30% {
        opacity: 1;
        visibility: visible !important;
    }
    100% {
        opacity: 1;
        visibility: visible !important;
    }
}

@keyframes fadeOutVisiblity {
    0% {
        opacity: 1;
        visibility: visible !important;
    }
    100% {
        opacity: 0;
        visibility: hidden !important;
    }
}

@import 'src/variables.scss';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $background_color;

    background-size: cover !important;
    background-repeat: no-repeat !important;
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
.App,
body > #root {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    border: none;
    width: 17px;
    height: 17px;
}

::-webkit-scrollbar-thumb {
    min-height: 30px;
    border-radius: $border-radius;
    border: 4px solid $color_transparent;
    background: rgba($color_black, 0.3);
    -webkit-background-clip: content-box;
    background-clip: content-box;
}

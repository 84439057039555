@import 'src/variables.scss';

.MuiTable-root {
    // .MuiTableCell-root{
    //     padding: 5px 20px;
    // }

    .MuiTableCell-head {
        background-color: $primary_color;
        color: $color_white;
        overflow: hidden;
        text-transform: uppercase;
        font-weight: 600;
    }

    .MuiTableBody-root {
        overflow: auto;
    }

    .MuiTableCell-root.totalCost {
        font-weight: 500;
    }
}

.snc-dark-table {
    .MuiTable-root {
        .MuiTableCell-head {
            background-color: $grey_blue_dark_color;
        }
        // Que te den, MaturialUI
        tbody {
            .MuiTableRow-root.Mui-selected,
            .MuiTableRow-root.Mui-selected:hover {
                background-color: rgba(0, 0, 0, 0.08);
            }
            tr {
                //background-color: goldenrod;
                td:first-child {
                    //background-color: orangered;
                    .MuiButtonBase-root {
                        //background-color: goldenrod !important;
                        .MuiSvgIcon-root {
                            color: #212529 !important;
                        }
                    }
                }
            }
        }
    }
}

// MUIDataTable-paper

.MuiTableCell-head {
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: #fff !important;
    }

    .MuiCheckbox-indeterminate {
        color: #fff !important;
    }

    .MuiCheckbox-root {
        color: #fff !important;
    }
}

.MuiTableRow-root:nth-child(even) {
    .MuiTableCell-body {
        background-color: #f6f6f6 !important;
    }
}

.MuiTableRow-root:nth-child(odd) {
    .MuiTableCell-body {
        background-color: white !important;
    }
}

@import 'src/variables.scss';

.snc-styled-list {
    list-style-type: none;

    margin: 0;
    margin-left: 3em;
    padding: 0;

    counter-reset: li-counter;

    > li.completed {
        color: $color_grey;
        border-left: 2px solid $color_grey;

        ::before {
            color: $color_grey !important;
        }
    }

    > li.completed::before {
        color: $color_grey;
    }

    > li {
        position: relative;
        margin-bottom: 20px;
        padding-left: 0.5em;
        min-height: 3em;
        border-left: 2px solid $primary_color;
    }

    > li::before {
        position: absolute;
        top: 0;
        left: -1em;
        width: 0.8em;

        font-size: 2.25em; // font-size: 2.5em;
        line-height: 1;
        font-weight: bold;
        text-align: right;
        color: $primary_color;

        content: counter(li-counter);
        counter-increment: li-counter;
    }
}

.process-font-size {
    font-size: 15px;
}

@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';

//THIS FILE SHOULD NOT BE IMPORTED FROM A COMPONENT, JUST FROM OTHER SCSS

$default_primary_color: #ff4347;
$default_dark_tabs_background_color: #ff4347 !default;
$default_color_action: #ff4347;

$primary_color: var(--primary_color, $default_primary_color);

$color_transparent: transparent !default;
$color_white: #fff !default;
$color_black: #000 !default;

$grey_blue_dark_color: #212529 !default;
$grey_blue_light_color: #adb5bd !default;
$color_grey: #919191 !default;
$color_grey_2: #5e656b !default;

$background_color: #e3e3e3;
$darker_background_color: #d6d6d6;

$disabled_color: #eaeaea !default;

$dark_tabs_background_color: var(--dark_tabs_background_color, $default_dark_tabs_background_color);
$color_action: var(--color_action, $default_color_action);

$border-radius: 0.4rem;

@import 'src/variables.scss';

.overflow-y-auto {
    overflow-y: auto;
    overflow-x: hidden;
}

.grab {
    cursor: grab;
}

.navLinkNoCss {
    outline: none !important;
    text-decoration: none !important;
}

.no-opacity {
    opacity: 0;
    pointer-events: none;
}

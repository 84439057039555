@import 'src/variables.scss';

.capitalize {
    text-transform: lowercase;
    display: inline-block;
}

.capitalize:first-letter {
    text-transform: uppercase;
}

.wrap-text {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.snc-text1,
.snc-text2,
.snc-text3,
.snc-text4,
.snc-text5 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 0px;
    font-weight: 600;
}

.snc-text1 {
    font-size: 40px;
}

.snc-text2 {
    font-size: 30px;
}

.snc-text3 {
    font-size: 25px;
}

.snc-text4 {
    font-size: 20px;
}

.snc-text5 {
    font-size: 15px;
}
